
import { defineComponent, ref } from "vue";
import base from "@/api/base.js";
import * as XLSX from "xlsx";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

interface Filter {
  status: string;
  code: string;
}

export default defineComponent({
  name: "schedules",
  components: { VPagination },

  data() {
    return {
      schedules: [],
      searchcode: "cmp_nm",
      searchdata: "",
      currentPage: 1,
      perPage: 3,
      rows: 10,
      excelData: [
        {
          inspector: "",
          check_date: "",
          store: "",
          check_version: "",
          check_ver_ty: "",
        },
      ],
      users: [{ cmp: {} }],
      stores: [{ cmp: {} }],
      checkversions: [{ brand: {} }],
      excelResult: {},
      page: 1,
    };
  },
  created() {
    this.getListData();
  },
  mounted() {
    base
      .getUserList()
      .then((res) => {
        console.log(res);
        this.users = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
    base
      .getStoreList()
      .then((res) => {
        console.log(res);
        this.stores = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
    base
      .getCheckVersionList()
      .then((res) => {
        console.log(res);
        this.checkversions = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    getSearchResult() {
      this.page = 1;
      this.getListData();
    },
    getListData() {
      const param = {
        [this.searchcode]: this.searchdata,
        page: this.page,
      };
      base
        .getScheduleList(param)
        .then((res) => {
          console.log(res);
          this.schedules = res.data;
        })
        .catch((err) => {
          console.log(err);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },

    fnView(id) {
      console.log(id);
      sessionStorage.setItem("id", id);
      this.$router.push({
        name: "schedule-detail",
        params: { id: id },
      });
    },

    readFile(event) {
      const file = event.target.files[0];
      const fileName = file.name;

      const reader = new FileReader();
      const tmpResult = {};

      reader.onload = (e) => {
        let data: string | ArrayBuffer = e.target.result;
        data = new Uint8Array(data as ArrayBuffer);

        const excelFile = XLSX.read(data, {
          type: "array",
          cellDates: true,
          dateNF: "YYYY-MM-DD",
        });

        excelFile.SheetNames.forEach(function(sheetName) {
          const roa = XLSX.utils.sheet_to_json(excelFile.Sheets[sheetName], {
            header: 1,
            raw: false,
          });
          if (roa.length) tmpResult[sheetName] = roa;
        });
        this.result = tmpResult;
        console.log(tmpResult);
        this.transData();
      };
      reader.readAsArrayBuffer(file);
    },

    transData() {
      const data = this.result.newSchedule;
      console.log(data);
      for (let i = 1; i < data.length; i++) {
        if (data[i][0]) {
          this.excelResult = {
            inspector: data[i][0],
            check_date: data[i][1],
            store: [data[i][2]],
            check_version: data[i][3],
            check_ver_ty: data[i][4],
          };

          console.log(this.excelResult);
          this.submitForm(this.excelResult);
        }
      }
    },

    excelDownFunc() {
      const version_ty = [
        {
          "직영 정기점검": "QSCVT10010",
          "가맹 정기점검": "QSCVT10020",
          "신규오픈 매장점검": "QSCVT10030",
          "폐점 점검": "QSCVT10040",
          "양도양수 매장점검": "QSCVT10050",
        },
      ];
      const users = this.users;
      users.map((e) => {
        e.cmp = e.cmp.cmp_nm;
      });
      const stores = this.stores;
      stores.map((e) => {
        e.cmp = e.cmp.cmp_nm;
      });
      const checkversions = this.checkversions;
      checkversions.map((e) => {
        e.brand = e.brand.brand_nm;
      });
      // console.log(user);
      const ws1 = XLSX.utils.json_to_sheet(this.excelData);
      const ws2 = XLSX.utils.json_to_sheet(users);
      const ws3 = XLSX.utils.json_to_sheet(stores);
      const ws4 = XLSX.utils.json_to_sheet(checkversions);
      const ws5 = XLSX.utils.json_to_sheet(version_ty);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws1, "newSchedule");
      XLSX.utils.book_append_sheet(wb, ws2, "inspector");
      XLSX.utils.book_append_sheet(wb, ws3, "store");
      XLSX.utils.book_append_sheet(wb, ws4, "check_version");
      XLSX.utils.book_append_sheet(wb, ws5, "check_ver_ty");
      XLSX.writeFile(wb, "format.xlsx");
    },

    async submitForm(data) {
      await base
        .newSchedule(data)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            alert("신규 일정을 등록했습니다.");
            this.$router.push("/schedules");
          } else {
            alert(res.status);
          }
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },
  },
  setup() {
    const data = ref<Filter>({
      status: "1",
      code: "1",
    });

    return {
      data,
    };
  },
});
